import thousandSeparator from "../../utils/numberUtils";
import DetailPanel from "../../components/DetailPanel";

const BasicDetail = ({ order }) => {

    return <DetailPanel details={[{
        name: 'Megrendelő neve',
        value: order?.name
    }, {
        name: 'Telefonszáma',
        value: order?.phone
    }, {
        name: 'Email címe',
        value: order?.email
    }, {
        name: 'Ár',
        value: `${thousandSeparator(order.price)} Ft`
    }]}
    />
};

export default BasicDetail;
