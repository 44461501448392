import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Stack,
    Typography,
    IconButton,
    Button, Box,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import thousandSeparator from '../../utils/numberUtils';
import DetailPanel from "../../components/DetailPanel";
import DisabledWrapper from "../../components/DisabledWrapper";
import {hasPermission} from "../../utils/permissionUtils";
import AuthContext from "../../context/AuthContext";
import AuthConfigContext from "../../context/AuthConfigContext";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import szamlazz from "../../assets/szamlazz.jpg";
import billingo from "../../assets/billingo_logo.png";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DinoDialog from "../../components/Dialog";

const OrderDetail = ({order, close, createInvoice, downloadInvoice, sendInvoice}) => {
    const {getUser} = useContext(AuthContext)
    const {billingAvailable} = useContext(AuthConfigContext)
    const {getData: fetchOrder} = useCallDataApi('order')
    const [loading, setLoading] = useState(false)
    const [documents, setDocuments] = useState([])
    const [multipleInvoice, setMultipleInvoice] = useState(false)
    const {hasPlan} = useContext(AuthConfigContext);
    const user = getUser()

    useEffect(() => {
        setLoading(true)
        fetchOrder(`${order?.id}/get_documents`)
            .then(d => setDocuments(d))
            .finally(() => setLoading(false))
    }, []);

    const billingDataProvided = () =>
        order?.billing_name && order?.billing_country && order?.billing_city && order?.billing_postal_code && order?.billing_address;

    const getPaymentMethod = paymentMethod => {
        const map = {
            'card': 'Helyszíni fizetés',
            'simple': 'Online fiztés'
        }
        return map[paymentMethod]
    }

    const alreadyHasInvoice = () => !!documents?.find(d => d?.document_type === 1)

    const canCreateInvoice = () => {
        if (alreadyHasInvoice()) {
            setMultipleInvoice(true)
        } else {
            createInvoice(order?.id)
        }
    }

    const renderDocument = d => {
        const documentTypes = {
            1: 'Számla',
            2: 'Előleg számla',
            3: 'Sztornó számla'
        }
        let serviceTypes = {
            'szamlazz': szamlazz,
            'billingo': billingo
        }
        return <Stack key={d?.id} direction='row' alignItems='center' spacing={2}>
            <Avatar src={serviceTypes[d?.billing_service]}/>
            <Stack>
                <Typography variant='subtitle2'>{d?.document_id}</Typography>
                <Typography variant='body2'>{documentTypes[d?.document_type]}</Typography>
            </Stack>
            <IconButton variant='dino' onClick={() => downloadInvoice(order?.id, d?.id)} title='Dokumentum letöltése'>
                <DownloadForOfflineRoundedIcon/>
            </IconButton>
            <DisabledWrapper disabledCause={[{
                disabled: !hasPlan('invoice', 'view'),
                message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
            }]}>
                <IconButton variant='dino' onClick={() => sendInvoice(order?.id, d?.id)} title='Dokumentum kiküldése'>
                    <SendRoundedIcon/>
                </IconButton>
            </DisabledWrapper>
        </Stack>
    }

    const getBillingAddress = () => {
        return order?.billing_address ? `${order?.billing_country} ${order?.billing_postal_code} ${order?.billing_city} ${order?.billing_address}` : '-';
    }

    return (
        <div style={{padding: '48px'}}>
            <IconButton onClick={close} color="primary" aria-label="close"
                        style={{position: 'absolute', top: 10, left: 10}}>
                <CloseOutlinedIcon/>
            </IconButton>
            <Stack spacing={3}>
                <Stack direction="row" spacing={2}>
                    <Avatar sx={{width: 69, height: 69, background: '#9ED4B6', color: '#478A64'}}>
                        {order?.first_name?.charAt(0)}{order?.last_name?.charAt(0)}
                    </Avatar>
                    <Stack spacing={1} style={{alignSelf: 'center'}}>
                        <Typography variant="headline6">{order?.name}</Typography>
                        <Typography variant="caption" style={{color: '#478A64'}}>Elfogadott</Typography>
                    </Stack>
                </Stack>

                <Typography variant="subtitle1" color='primary.600'>Vevői adatok</Typography>
                <DetailPanel details={[{
                    name: 'Név',
                    value: order?.name
                }, {
                    name: 'Telefonszám',
                    value: order?.phone
                }, {
                    name: 'Email',
                    value: order?.email
                }]}/>
                <Typography variant="subtitle1" color='primary.600'>Számlázási adatok</Typography>
                <DetailPanel details={[{
                    name: 'Számlázási név',
                    value: order?.billing_name || '-'
                }, {
                    name: 'Adószám',
                    value: order?.billing_vat_number || '-'
                }, {
                    name: 'Számlázási cím',
                    value: getBillingAddress()
                }]}/>

                <Typography variant="subtitle1" color='primary.600'>Rendelés adatai</Typography>
                <DetailPanel details={[{
                    name: 'Fizetési mód',
                    value: getPaymentMethod(order?.payment)
                }, {
                    name: 'Ár',
                    value: thousandSeparator(order?.price) + ' Ft'
                }, {
                    name: 'Megjegyzés',
                    value: order?.comment || '-'
                }]}/>

                {order?.ordered_products && <Stack spacing={2}>
                    <Typography variant='subtitle1' color='primary.600'>Rendelt termékek</Typography>
                    <DetailPanel details={order?.ordered_products?.map(o => ({
                        name: `${o?.name} (${o?.quantity} db)`,
                        value: thousandSeparator(o?.price * o?.quantity) + ' Ft'
                    }))}/>
                </Stack>}

                {(documents.length > 0 && hasPermission(user, 'invoice', 'view')) &&
                    <Stack spacing={2}>
                        <Typography variant='subtitle1' color='primary.600'>
                            Dokumentumok
                        </Typography>
                        {documents?.map(d => renderDocument(d))}
                    </Stack>
                }

                <Box sx={{height: '50px'}}/>

                <Stack spacing={2} sx={{position: 'fixed', bottom: 0, background: '#fff', padding: 1, width: '100%'}}>
                    <Typography variant='subtitle2' color='primary.main'>Kezelés:</Typography>
                    <Stack direction='row' spacing={2}>
                        <DisabledWrapper
                            disabledCause={[{
                                disabled: !hasPlan('invoice', 'create'),
                                message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
                            }, {
                                disabled: !hasPermission(user, 'invoice', 'create'),
                                message: 'Nincs jogosultságod a művelet végrehajtásához!'
                            }, {
                                disabled: !billingAvailable(),
                                message: 'Nincs bekapcsolva aktív számlázás!'
                            }, {
                                disabled: !billingDataProvided(),
                                message: 'Nem adott meg számlázási adatokat!'
                            }]}>
                            <IconButton onClick={canCreateInvoice} variant='dino' title='Számla kiállítása'>
                                <ReceiptRoundedIcon/>
                            </IconButton>
                        </DisabledWrapper>
                    </Stack>
                </Stack>
            </Stack>

            <DinoDialog
                open={multipleInvoice}
                title='Figyelem!'
                subtitle='Ehhez a megrendeléshez már tartozik számla, biztosan folytatod?'
                actions={<>
                    <Button variant='outlined' onClick={() => setMultipleInvoice(false)}>Mégsem</Button>
                    <Button variant='contained' onClick={() => {
                        setMultipleInvoice(false)
                        createInvoice(order?.id)
                    }} endIcon={<ReceiptRoundedIcon/>}>Igen</Button>
                </>}
                handleClose={() => setMultipleInvoice(false)}
            />

            <Loading isLoading={loading}/>
        </div>
    );
};

export default OrderDetail;
