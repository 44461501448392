import AuthBase from "../AuthBase";
import {
    Button,
    Grid,
    Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import businessTemplate from '../website/BusinessTemplate';
import userTemplate from "../colleague/UserTemplate";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import Loading from "../../components/Loading";
import AuthContext from "../../context/AuthContext";
// import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import OwnData from "./components/OwnData";
import PasswordReset from "./components/PasswordReset";
import Payment from "./payment/Payment";
import Billing from "./billingNew/Billing";
import ApiKey from "./api-key/ApiKey";
import Subscription from "./components/Subscription";
import Cards from "./components/Cards";
import DeleteAccount from "./components/DeleteAccount";
import Calendar from "./components/Calendar";
import SmsTokens from "./components/SmsTokens";
import InvoiceData from "./components/InvoiceData";


const Settings = () => {
    const [me, setMe] = useState(userTemplate)
    const [editedMe, setEditedMe] = useState(userTemplate)
    const [business, setBusiness] = useState(businessTemplate)
    const [editedBusiness, setEditedBusiness] = useState(businessTemplate)

    const [loading, setLoading] = useState(true)
    const {getData: fetchMe, updateData: updateMe, postData} = useCallDataApi('colleague')
    const {getData: fetchBusiness, updateData: updateBusiness} = useCallDataApi('business')

    const {logoutUser} = useContext(AuthContext)
    // const {google_client_id} = useContext(ConfigContext)
    const passwordResetForm = useRef(null)

    const companyBillingRef = useRef(null)
    const personBillingRef = useRef(null)
    const ownDataReference = useRef(null)


    useEffect(() => {
        Promise.all([
            fetchMe('get_user'),
            fetchBusiness('get_business')
        ]).then(([u, b]) => {
            setMe(u)
            setEditedMe(u)
            setBusiness(b)
            setEditedBusiness(b)
        }).finally(() => setLoading(false))
    }, [])

    const editBusiness = data => {
        setLoading(true)
        updateBusiness(business.id, data)
            .then(b => {
                setBusiness(b)
                setEditedBusiness(b)
            })
            .finally(() => setLoading(false))
    }

    const handleEditeBusiness = () => {
        let data
        if (editedBusiness?.incorporation_type === 'company') {
            data = companyBillingRef.current.getData()
        } else {
            data = personBillingRef.current.getData()
        }
        if (data) editBusiness({
            incorporation_type: editedBusiness?.incorporation_type,
            ...data
        })
    }

    const editMe = () => {
        const data = ownDataReference.current.getData()
        if (data) {
            setLoading(true)
            updateMe(`${me?.id}/`, data)
                .then(b => setMe(b))
                .finally(() => setLoading(false))
        }
    }

    const isDifferentData = (original, edited, diffList) => {
        for (let diff of diffList) {
            if (original[diff] !== edited[diff]) {
                return true
            }
        }
        return false
    }


    const changePassword = () => {
        const data = passwordResetForm.current.getData()
        if (data) {
            setLoading(true)
            postData('change_password/', data)
                .then(() => logoutUser())
                .catch(() => console.log('wrong password'))
                .finally(() => setLoading(false))
        }
    }

    return <AuthBase label='Beállítások'>
        <div className="main">
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Saját adatok módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>
                            Itt tudod módosítani az adminiszrátori felhasználó
                            adatait. Ezen adatokat a Kollégák résznél is módosíthatod.
                        </Typography>
                        <OwnData reference={ownDataReference} data={editedMe} setData={setEditedMe}/>
                        <Button
                            endIcon={<SaveRoundedIcon/>}
                            onClick={editMe}
                            variant='contained'
                            disabled={!isDifferentData(me, editedMe, ['last_name', 'first_name', 'title'])}
                        >
                            Módosítások mentése
                        </Button>
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>
                            Számlázási és SimplePay adattovábbítási adatok módosítása
                        </Typography>
                        <Typography variant='body2' color='#536B74'>
                            Itt tudod modósítani a számlázási és SimplePay adattovábbítási nyilatkozathoz tartozó adataidat.
                        </Typography>
                        <InvoiceData
                            editedBusiness={editedBusiness}
                            setEditedBusiness={setEditedBusiness}
                            personBillingRef={personBillingRef}
                            companyBillingRef={companyBillingRef}
                        />
                        <Stack direction='row' marginTop={2}>
                            <Button
                                endIcon={<SaveRoundedIcon/>}
                                variant='contained'
                                onClick={handleEditeBusiness}
                                disabled={!isDifferentData(business, editedBusiness, [
                                    'incorporation_type',
                                    'name',
                                    'country',
                                    'postal_code',
                                    'state',
                                    'city',
                                    'address',
                                    'vat_number',
                                ])}
                            >
                                Módosítások mentése
                            </Button>
                        </Stack>
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Jelszó módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>
                            Kérjük változtasd meg rendszeresen a jelszavadat és ne add meg senkinek!
                        </Typography>
                        <PasswordReset reference={passwordResetForm}/>
                        <Button
                            endIcon={<SaveRoundedIcon/>}
                            onClick={changePassword}
                            variant='contained'
                        >
                            Jelszó módosítása
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Billing/>
                </Grid>

                <Grid item xs={12}>
                    <Payment/>
                </Grid>

                <Grid item xs={12}>
                    <SmsTokens business={business}/>
                </Grid>

                <Grid item xs={12}>
                    <Subscription
                        business={business}
                        setBusiness={setBusiness}
                        setEditedBusiness={setEditedBusiness}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Cards />
                </Grid>

                <Grid item xs={12}>
                    <Calendar />
                </Grid>

                <Grid item xs={12}>
                    <ApiKey/>
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <div className="white-card">*/}
                {/*        <Typography variant='subtitle2'>Összekötés Google fiókkal</Typography>*/}
                {/*        <Typography variant='body2' color='#536B74' gutterBottom>Ha összekötöd a DinoBooking*/}
                {/*            regisztrációdat a Google fiókoddal, onnantól azzal is be tudsz jelentkezni egy*/}
                {/*            kattintással.</Typography>*/}
                {/*        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                {/*            {!me?.account ? <GoogleOAuthProvider clientId={google_client_id}>*/}
                {/*                <GoogleLogin*/}
                {/*                    shape='pill'*/}
                {/*                    text='continue_with'*/}
                {/*                    onSuccess={response => postData('connect_with_google', {token: response.credential})}*/}
                {/*                    onError={() => {*/}
                {/*                        enqueueSnackbar('Nem található összekötött fiók!');*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </GoogleOAuthProvider> : <Typography>Összekötve ezzel: {me?.account}</Typography>}*/}
                {/*        </Box>*/}
                {/*    </div>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <DeleteAccount />
                </Grid>
            </Grid>
        </div>



        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Settings