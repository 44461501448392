import {MenuItem, Stack} from "@mui/material";
import {useContext, useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import CustomSelect from "../../../../components/formValidation/CustomSelect";
import CustomSwitch from "../../../../components/formValidation/CustomSwitch";
import Typography from "@mui/material/Typography";
import DisabledWrapper from "../../../../components/DisabledWrapper";
import AuthConfigContext from "../../../../context/AuthConfigContext";

const Payment = ({reference, data, setData, paymentAuthorized}) => {
    const {hasPlan} = useContext(AuthConfigContext);
    const [errors, setErrors] = useState({
        is_pay_cash_or_card: false,
        is_pay_online: false,
        is_pay_advance: false,
        advance_amount: false,
    });

    const validators = {
        is_pay_cash_or_card: [],
        is_pay_online: [],
        is_pay_advance: [],
        advance_amount: [],
    }

    const validateAtLeastOneSelected = obj => {
        const {is_pay_cash_or_card, is_pay_online, is_pay_advance} = obj
        const isAtLeastOneSelected = is_pay_cash_or_card || is_pay_online || is_pay_advance
        return {valid: isAtLeastOneSelected, error: 'Legalább egy fizetési lehetőséget ki kell választani!'}
    }

    const isPayOnlineDisabledCauses = [
        {
            disabled: !hasPlan('payment', 'update'),
            message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a beállításokban a használathoz!'
        },
        {
            disabled: !paymentAuthorized,
            message: 'Nincs bekapcsolva az online fizetés! A beállítások menüpontban tudod hozzáadni a fizetési módot.'
        },
    ];


    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}
                              customValidators={[validateAtLeastOneSelected]}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='is_pay_cash_or_card'
                labelOn='Helyszíni fizetés bekapcsolva'
                labelOff='Helyszíni fizetés kikapcsolva'
            />
            <DisabledWrapper disabledCause={isPayOnlineDisabledCauses}>
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='is_pay_online'
                    labelOn='Online fizetés bekapcsolva'
                    labelOff='Online fizetés kikapcsolva'
                />
            </DisabledWrapper>
            <DisabledWrapper disabledCause={isPayOnlineDisabledCauses}>
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='is_pay_advance'
                    labelOn='Előleg fizetés bekapcsolva'
                    labelOff='Előleg fizetés kikapcsolva'
                />
            </DisabledWrapper>
            {data?.is_pay_advance &&
                <Stack spacing={1}>
                    <Typography variant='caption' color='primary.main'>Előleg mértéke</Typography>
                    <DisabledWrapper disabledCause={isPayOnlineDisabledCauses}>
                        <CustomSelect
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            variant='standard'
                            name='advance_amount'
                            sx={{width: '100%'}}
                        >
                            <MenuItem value={10}>10%</MenuItem>
                            <MenuItem value={25}>25%</MenuItem>
                            <MenuItem value={50}>50%</MenuItem>
                        </CustomSelect>
                    </DisabledWrapper>
                </Stack>
            }
        </Stack>
    </ErrorHandlingForm>
}

export default Payment