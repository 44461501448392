import AuthBase from "../AuthBase";
import {Avatar, Stack, useTheme} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConfigContext from "../../context/ConfigContext";
import DinoDialog from "../../components/Dialog";
import Button from "@mui/material/Button";
import CircleButton from "../../components/CircleButton";
import {useHistory} from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Loading from "../../components/Loading";
import ExtraItemData from "./components/ExtraItemData";
import NoItems from "../../components/NoItems";


const ExtraItem = ({id, name, description, blur, image, numberOfServices, edit, destroy}) => {
    const theme = useTheme()
    const history = useHistory()
    const {baseUrl} = useContext(ConfigContext)

    return <Stack sx={{flexDirection: {xs: 'column', sm: 'column', md: 'row'}}} spacing={2}
                  style={{width: '100%', justifyContent: 'space-between'}}>
        <Stack direction='row' spacing={2}>

            <Avatar src={image?.startsWith('http') ? image : baseUrl + image} sx={{
                width: 60,
                height: 60,
                border: '2px solid',
                borderColor: theme.palette.primary[200],
                alignSelf: 'center',
            }}/>
            <div style={{alignSelf: 'center'}}>
                <Typography variant="subtitle1" color='primary.600'>{name}</Typography>
                <Typography
                    variant="body2"
                    color='#536B74'
                >
                    {description.substring(0, 30)}{description?.length > 30 && '...'}
                </Typography>
            </div>
        </Stack>

        <Stack spacing={1} direction='row' sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
            <CircleButton icon={<DeleteRoundedIcon/>} onClick={destroy}/>
            <CircleButton icon={<EditIcon/>} onClick={edit}/>
            <CircleButton icon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/extra-items/${id}`)
            }} inverted/>
        </Stack>

        <Stack spacing={1} sx={{display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
            <Button variant='outlined' endIcon={<EditIcon/>} onClick={edit}>Szerkesztés</Button>
            <Button variant='contained' endIcon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/extra-items/${id}`)
            }}>Megnyitás</Button>
        </Stack>
    </Stack>
}

const ExtraItems = () => {
    const [extraItems, setExtraItems] = useState([])
    const [current, setCurrent] = useState(1)
    const [editOpen, setEditOpen] = useState(false)
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const extraItemForm = useRef(null)


    const {
        getData: fetchExtraItems,
        updateDataFormData: updateExtraItem,
        deleteData: deleteExtraItem,
        createDataFormData: createExtraItem
    } = useCallDataApi('extra-items')

    const buttons = [{
        name: 'Új termék hozzáadása',
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlinedIcon/>,
            onClick: () => {
                setNewOpen(true)
            }
        }
    }]

    const filterButtons = [
        {
            name: 'Új termék',
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlinedIcon/>,
                onClick: () => {
                    setNewOpen(true)
                }
            }
        }
    ]

    const getExtraItem = (id) => {
        return extraItems.find(c => c.id === id)
    }

    const newExtraItem = data => {
        setLoading(true)
        createExtraItem(data)
            .then(c => setExtraItems([...extraItems, c]))
            .finally(() => setLoading(false))
    }

    const editExtraItem = data => {
        setLoading(true)
        updateExtraItem(`${current}`, data).then(c => {
            const index = extraItems.findIndex(item => item.id === current);
            if (index !== -1) {
                const updatedItems = [...extraItems]
                updatedItems[index] = c
                setExtraItems(updatedItems)
            }
        }).finally(() => setLoading(false))
    }

    const removeExtraItem = (id) => {
        setLoading(true)
        deleteExtraItem(id).then(() => {
            setExtraItems([...extraItems.filter(c => c.id !== id)])
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        const getData = async () => {
            const extra = await fetchExtraItems('get_for_business')
            if (extra) setExtraItems(extra)
        }
        getData()
            .then(() => setLoading(false))
    }, [])

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            removeExtraItem(current)
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>
    const editActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setEditOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            const data = extraItemForm.current.getData()
            if (typeof data?.image === 'string') delete data['image'] // Ugh... If the picture is already uploaded leave it alone
            if (!data) return
            if (Object.keys(data).length > 0) {
                editExtraItem(data)
                setEditOpen(false)
            }
        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
    </>
    const newActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            const data = extraItemForm.current.getData()
            if (!data) return
            if (Object.keys(data).length > 0) {
                newExtraItem(data)
                setNewOpen(false)
            }
        }} endIcon={<AddCircleOutlinedIcon/>}>Hozzáadás</Button>
    </>


    return <AuthBase label='Termékek' buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            {extraItems.length === 0 ? <NoItems
                    title='Nincsenek termékek'
                    description='Adj hozzá új terméket a fenti gombbal! 👆'
                /> :
                <Stack>
                    <Typography variant='subtitle2' color='#536B74' gutterBottom>
                        {extraItems?.length} termék
                    </Typography>
                    <div className="white-card">
                        <Stack spacing={2}>
                            {extraItems.map((c, i) =>
                                <ExtraItem
                                    key={`ExtraItem_${i}`}
                                    name={c.name}
                                    id={c.id}
                                    description={c.description}
                                    blur={c.blur}
                                    image={c.image}
                                    numberOfServices={c.number_of_services}
                                    edit={() => {
                                        setCurrent(c.id)
                                        setEditOpen(true)
                                    }}
                                    destroy={() => {
                                        setCurrent(c.id)
                                        setDeleteOpen(true)
                                    }}
                                />
                            )}
                        </Stack>
                    </div>
                </Stack>}
        </div>

        <DinoDialog open={newOpen}
                    title='Új termék hozzáadása'
                    subtitle='Hozz létre egyedi kategóriákat és ahhoz tartozó szolgáltatásokat egyszerűen. Add meg a termék nevét és leírását!'
                    actions={newActions}
                    handleClose={() => setNewOpen(false)}>
            <ExtraItemData reference={extraItemForm}/>
        </DinoDialog>

        <DinoDialog open={editOpen}
                    title={`${getExtraItem(current)?.name} termék szerkesztése`}
                    subtitle='Add meg a szolgáltatás nevét és leírását!'
                    actions={editActions}
                    handleClose={() => setEditOpen(false)}>
            <ExtraItemData obj={getExtraItem(current)} reference={extraItemForm}/>
        </DinoDialog>

        <DinoDialog open={deleteOpen}
                    title={`Biztosan kitörli ezt: ${getExtraItem(current)?.name}`}
                    subtitle='Az összes hozzá tartozó szolgáltatás és foglalás törlődni fog!'
                    actions={deleteActions}
                    handleClose={() => setDeleteOpen(false)}>
        </DinoDialog>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default ExtraItems