import React from "react";
import {Box, Button, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import thousandSeparator from "../../../utils/numberUtils";
import * as MuiIcons from "@mui/icons-material";
import {hasPermission} from "../../../utils/permissionUtils";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import DisabledWrapper from "../../../components/DisabledWrapper"; // Import all icons

const Plan = ({isCurrent, isNext, name, description, price, months, icon, changePlane}) => {
    const theme = useTheme();
    const {getUser} = useContext(AuthContext)
    const user = getUser()
    // Dynamically get the icon component based on the name
    const Icon = MuiIcons[icon] || MuiIcons.HelpOutline; // Default to a generic icon if not found
    const disabledCause = [{
        disabled: !hasPermission(user, 'subscription', 'update'),
        message: 'Nincs jogosultságod a művelet végrehajtásához!'
    }, {
        disabled: isNext,
        message: 'Ez a csomagot választottad a következő időszakra!'
    }]
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                border: "1px solid",
                borderColor: "primary.50",
                borderRadius: "16px",
                padding: "16px 24px",
            }}
        >
            <Icon sx={{color: theme.palette.primary.main, fontSize: "50px"}}/>
            <Stack spacing={1}>
                <Typography variant="subtitle1" color="primary.600">
                    {name}
                </Typography>
                {(!isNext && isCurrent) && <Typography variant="body1">
                    (Jelenlegi)
                </Typography>}
                <Typography variant="body2" color="#38474D">
                    {description}
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.700"
                >
                    {thousandSeparator(price)} Ft/{months} hónap
                </Typography>
                <Box>
                    <DisabledWrapper disabledCause={disabledCause}>
                        <Button
                            endIcon={<MuiIcons.CurrencyExchangeRounded/>}
                            variant="contained"
                            onClick={changePlane}
                        >
                            Ezt választom
                        </Button>
                    </DisabledWrapper>
                </Box>
            </Stack>
        </Stack>
    );
};

export default Plan;
