import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const NoItems = ({title, description}) => {
    return <Box sx={{border: '1px dashed', borderColor: '#6F8E9A', borderRadius: '16px'}}>
        <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
            <Typography variant='subtitle2' color='#6F8E9A'>{title}</Typography>
            <Typography variant='body2' color='#6F8E9A'>
                {description}
            </Typography>
        </Stack>
    </Box>
}

export default NoItems