const ROLES = {
    owner: {
        colleague: {
            view: true,
            create: true,
            update: true,
            remove: (user, colleague) => user.id !== colleague.id,
            changeRole: (user, colleague) => user.user_id !== colleague.id,
        },
        billing: {
            create: true,
            delete: true,
            update: true
        },
        payment: {
            create: true,
            update: true,
            delete: true,
        },
        reservation: {
            create: true,
            update: true,
            delete: true,
            accept: true,
            decline: true
        },
        invoice: {
            view: true,
            create: true,
        },
        order: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        subscription: {
            view: true,
            create: true,
            update: true,
            delete: true,
        }
    },
    manager: {
        colleague: {
            view: true,
            create: true,
            update: (user, colleague) => colleague.role === 'employee',
            remove: (user, colleague) => user.id !== colleague.id,
            changeRole: (user, colleague) => user.user_id !== colleague.id,
        },
        billing: {
            create: true,
            update: true,
            delete: true,
        },
        payment: {
            create: true,
            update: true,
            delete: true,
        },
        reservation: {
            create: true,
            update: true,
            delete: true,
            accept: true,
            decline: true
        },
        invoice: {
            view: true,
            create: true,
        },
        order: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        subscription: {
            view: true,
            create: true,
            update: true,
            delete: true,
        }
    },
    employee: {
        colleague: {
            view: (user, colleague) => user.user_id === colleague.id,
            create: false,
            update: (user, colleague) => user.id === colleague.id,
            remove: false,
            changeRole: false
        },
        billing: {
            create: false,
            update: false,
            delete: false,
        },
        payment: {
            create: false,
            update: false,
            delete: false,
        },
        reservation: {
            create: true,
            update: (user, reservation) => reservation.price.colleague.id === user.user_id,
            delete: (user, reservation) => reservation.price.colleague.id === user.user_id,
            accept: (user, reservation) => reservation.price.colleague.id === user.user_id,
            decline: (user, reservation) => reservation.price.colleague.id === user.user_id
        },
        invoice: {
            view: false,
            create: false,
        },
        order: {
            view: true,
            create: true,
            update: false,
            delete: false,
        },
        subscription: {
            view: true,
            create: false,
            update: false,
            delete: false,
        }
    },
};

const hasPermission = (user, resource, action, data) => {
    const role = user.role
    if (!role) return false
    const permission = ROLES[role]?.[resource]?.[action]
    if (permission == null) return false;
    if (typeof permission === "boolean") return permission;
    return data != null && permission(user, data);
}

export {hasPermission}
