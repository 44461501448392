import {createContext, useContext} from "react";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import AuthContext from "./AuthContext";

const AuthConfigContext = createContext();

export default AuthConfigContext;

export const AuthConfigProvider = ({ children }) => {
    const [billingServices, setBillingServices] = useState([])
    const [features, setFeatures] = useState([])
    const {getData: fetchBusiness} = useCallDataApi('business')
    const {authTokens} = useContext(AuthContext)
    useEffect(() => {
        if (authTokens) {
            fetchBusiness('get_billing_services').then(b => {
                setBillingServices(b)
            })
            fetchBusiness('get_plan_features').then(f => {
                setFeatures(f)
            })
        }
    }, [authTokens])

    const billingAvailable = () => Boolean(billingServices.find(b => b.active))

    const hasPlan = (resource, action) => {
        if (Object.entries(features).length === 0) return false
        const feature = features?.find(f => f?.resource === resource)
        if (feature == null) return false;
        const permission = feature?.[action]
        if (permission == null) return false;
        if (typeof permission === "boolean") return permission;
        return false
    }

    const contextData = {
        billingServices,
        setBillingServices,
        billingAvailable,
        hasPlan
    }

    return <AuthConfigContext.Provider value={contextData}>{children}</AuthConfigContext.Provider>
}