import thousandSeparator from "../../utils/numberUtils";
import DetailPanel from "../../components/DetailPanel";

const DialogDetail = ({r}) => {
    const paymentMap = {
        card: 'Helyszíni fizetés',
        simple: 'Simplepay online',
    }

    return <DetailPanel
        details={[{
            name: 'Kliens neve',
            value: r?.name
        }, {
            name: 'Kliens telefonszáma',
            value: r?.phone
        }, {
            name: 'Kliens e-mail címe',
            value: r?.email
        }, {
            name: 'Kategória',
            value: r?.price?.service?.category?.name
        }, {
            name: 'Szolgáltatás',
            value: r?.price?.service?.name
        }, {
            name: 'Szolgáltató',
            value: r?.price?.colleague?.name
        }, {
            name: 'Ár',
            value: thousandSeparator(r?.price?.price) + ' Ft'
        }, {
            name: 'Fizetett előleg',
            value: thousandSeparator(r?.advance_payed) + ' Ft'
        }, {
            name: 'Fizetési mód',
            value: paymentMap[r?.payment]
        }, {
            name: 'Időpont',
            value: `${r?.date} | ${r?.start_time} - ${r?.end_time}`
        }, {
            name: 'Megjegyzés',
            value: r?.comment || '-'
        }]}
    />
}

export default DialogDetail