import Typography from "@mui/material/Typography";
import {Button, Grid} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DinoDialog from "../../../components/Dialog";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";
import Plan from "./Plan";
import {hasPermission} from "../../../utils/permissionUtils";
import AuthContext from "../../../context/AuthContext";
import DisabledWrapper from "../../../components/DisabledWrapper";

const Subscription = () => {
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const [subscription, setSubscription] = useState({})
    const {getData: fetchPlans} = useCallDataApi('plans')
    const {getData: fetchSubscription, postData: postSubscription} = useCallDataApi('subscription')

    const {getUser} = useContext(AuthContext)
    const user = getUser()

    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchPlans(),
            fetchSubscription('get_for_business')
        ]).then(([p, s]) => {
            setSubscription(s)
            setPlans(p)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changePlan = planId => {
        setLoading(true)
        postSubscription('change_plan', {new_plan: planId})
            .then(s => {
                setSubscription(s)
                window.location.reload()
            })
            .finally(() => setLoading(false))
    }

    const cancelSubscription = () => {
        setLoading(true)
        fetchSubscription('cancel_subscription')
            .then(s => setSubscription(s))
            .finally(() => setLoading(false))
    }

    // console.log(JSON.stringify(subscription))

    const [deleteSubscriptionDialogOpen, setDeleteSubscriptionDialogOpen] = useState(false)

    const subscriptionDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setDeleteSubscriptionDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteSubscriptionDialogOpen(false)
            cancelSubscription()
        }} endIcon={<DeleteForeverRoundedIcon/>}>Előfizetés lemondása</Button>
    </>

    const disabledCause = [{
        disabled: !hasPermission(user, 'subscription', 'update'),
        message: 'Nincs jogosultságod a művelet végrehajtásához!'
    }]

    return <div className="white-card">
        <Typography variant='subtitle2'>Előfizetési csomagok</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>
            Előfizetési csomag váltása esetén a kisebb csomag a következő fordulópontkor vált, tehát a kifizetett összeg elhasználódik és a következő fordulóponttól lesz kevesebb.
            A nagyobb csomagra váltás esetén pedig a különbözet kifizetése után lép életbe.
        </Typography>
        <Grid container spacing={1} marginBottom={2} marginTop={2}>
            {plans?.map(plan => <Grid item xs={12} key={`plan-${plan.id}`}>
                <Plan
                    isCurrent={plan.id === subscription?.plan}
                    isNext={plan.id === subscription?.next}
                    name={plan.name}
                    icon={plan.icon}
                    description={plan.description}
                    price={plan.price}
                    months={plan.months}
                    changePlane={() => changePlan(plan.id)}
                />
            </Grid>)}
        </Grid>
        <DisabledWrapper disabledCause={disabledCause}>
            <Button onClick={() => setDeleteSubscriptionDialogOpen(true)}>Előfizetés lemondása</Button>
        </DisabledWrapper>

        <DinoDialog open={deleteSubscriptionDialogOpen}
                    handleClose={() => setDeleteSubscriptionDialogOpen(false)}
                    title='Előfizetés lemondása'
                    subtitle='Biztosan lemondja az előfizetést? Az aktuális hónap végével nem fogja tudni használani a rendszert!'
                    actions={subscriptionDeleteActions}>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Subscription