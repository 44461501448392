import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import CustomSelect from "../../../components/formValidation/CustomSelect";
import {validateOneOf, validateRequired} from "../../../components/formValidation/validators";

const InvoiceAdditionalData = ({reference}) => {
    const [data, setData] = useState({
        payment_method: 'bankcard',
    })
    const [errors, setErrors] = useState({
        payment_method: false,
    })
    const validators = {
        payment_method: [validateRequired, value => validateOneOf(value, ['cash', 'wiretransfer', 'bankcard', 'online'])],
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
    >
        <Stack spacing={1}>
            <Stack spacing={1}>
                <Typography id="payment-method-label" variant="caption" color='primary.main'>
                    Fizetési mód*
                </Typography>
                <CustomSelect
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="payment_method"
                    variant="standard"
                    inputProps={{
                        "aria-labelledby": "payment-method-label",
                        "aria-required": "true",
                    }}
                >
                    <MenuItem value='cash'>Készpénz</MenuItem>
                    <MenuItem value='wiretransfer'>Átutalás</MenuItem>
                    <MenuItem value='bankcard'>Bankkártya</MenuItem>
                    <MenuItem value='online'>Online</MenuItem>
                </CustomSelect>
            </Stack>
        </Stack>
    </ErrorHandlingForm>
}

export default InvoiceAdditionalData