import Typography from "@mui/material/Typography";
import useCallDataApi from "../../../hooks/data";
import React, {useContext, useEffect, useRef, useState} from "react";
import Loading from "../../../components/Loading";
import {Stack} from "@mui/material";
import SimpleDisplay from "./SimpleDisplay";
import Button from "@mui/material/Button";
import DinoDialog from "../../../components/Dialog";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DisabledWrapper from "../../../components/DisabledWrapper";
import GeneralStepper from "../../../components/GeneralStepper";
import ProviderData from "./ProviderData";
import SimpleData from "./SimpleData";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Box from "@mui/material/Box";
import {hasPermission} from "../../../utils/permissionUtils";
import AuthContext from "../../../context/AuthContext";
import AuthConfigContext from "../../../context/AuthConfigContext";


const Payment = () => {
    const [current, setCurrent] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [paymentCredentials, setPaymentCredentials] = useState([])
    const [newOpen, setNewOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const {getData, createData, updateData, deleteData} = useCallDataApi('payment-credentials')
    const dataRef = useRef(null)
    const editRef = useRef(null)
    const {getUser} = useContext(AuthContext)
    const {hasPlan} = useContext(AuthConfigContext);
    const user = getUser()

    const [data, setData] = useState({
        payment_service: 'simple'
    })

    useEffect(() => {
        setLoading(true)
        getData()
            .then(data => setPaymentCredentials(data))
            .finally(() => setLoading(false))
    }, [])

    const findPaymentCredential = id => paymentCredentials.find(p => p.id === id)

    const createPaymentCredential = data => {
        setLoading(true)
        createData(data)
            .then(p => setPaymentCredentials([...paymentCredentials, p]))
            .finally(() => setLoading(false))
    }

    const editPaymentCredential = (id, data) => {
        setLoading(true)
        updateData(id, data)
            .then(updated => setPaymentCredentials(prevCredentials =>
                prevCredentials.map(credential =>
                    credential.id === id ? {...credential, ...updated} : credential
                )
            ))
            .finally(() => setLoading(false))
    }

    const deletePaymentCredential = id => {
        setLoading(true)
        deleteData(id)
            .then(() => setPaymentCredentials([...paymentCredentials.filter(p => p.id !== id)]))
            .finally(() => setLoading(false))
    }

    const makeActive = id => {
        setLoading(true)
        getData(`${id}/make_active`)
            .then(p => setPaymentCredentials(p))
            .finally(() => setLoading(false))
    }

    const deactivate = id => {
        setLoading(true)
        getData(`${id}/deactivate`)
            .then(p => setPaymentCredentials(p))
            .finally(() => setLoading(false))
    }

    const DISPLAY_MAP = {
        simple: <SimpleDisplay/>
    }

    const DATA_MAP = {
        simple: <SimpleData/>
    }

    const validateSelectProvider = () => {
        return {
            valid: !!data?.payment_service,
            error: 'A továbblépéshez szükséges egy szolgáltatót választani!'
        }
    }

    const validateData = () => {
        const data = dataRef.current.getData()
        return {
            valid: !!data,
            error: 'A továbblépéshez minden adatot meg kell adni!'
        }
    }

    const disabledCause = [{
        disabled: !hasPlan('payment', 'create'),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
    }, {
        disabled: !hasPermission(user, 'payment', 'create'),
        message: 'Nincs jogosultságod ehhez a művelethez!'
    }]

    return <div className='white-card'>
        <Typography variant='subtitle1'>Fizetési adatok módosítása</Typography>
        <Typography variant='body2' color='#536B74'>
            Adj hozzá vagy módosítsd a fizetési beállításaidat!
        </Typography>
        <Stack spacing={2} marginBottom={2} marginTop={2}>
            {paymentCredentials.map(cred => <Box key={`payment-credential-${cred?.id}`}>{
                React.cloneElement(DISPLAY_MAP[cred?.payment_service] || <div/>, {
                    credential: cred,
                    onMakeActive: id => makeActive(id),
                    onDeactivate: id => deactivate(id),
                    onEdit: id => {
                        setCurrent(id)
                        setEditOpen(true)
                    },
                    onDelete: id => {
                        setCurrent(id)
                        setDeleteOpen(true)
                    }
                })
            }</Box>)}
        </Stack>
        <DisabledWrapper disabledCause={disabledCause}>
            <Button
                variant='contained'
                onClick={() => setNewOpen(true)}
                endIcon={<AddCircleRoundedIcon/>}
            >
                Új hozzáadása
            </Button>
        </DisabledWrapper>
        <Loading isLoading={loading}/>


        <DinoDialog
            open={deleteOpen}
            title='Fizetési mód törlése'
            subtitle='Biztos vagy benne hogy szeretnéd törölni a fizetési módot?'
            handleClose={() => setDeleteOpen(false)}
            actions={<Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setDeleteOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setDeleteOpen(false)
                    deletePaymentCredential(current)
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>}
        />


        <DinoDialog
            open={newOpen}
            title='Új fizetési mód hozzáadása'
            subtitle='Válassz majd írd be a fizetési mód nevét és adatait!'
            handleClose={() => setNewOpen(false)}
        >
            <GeneralStepper
                steps={[{
                    label: 'Szolgáltató kiválasztása:',
                    component: <ProviderData data={data} setData={setData}/>,
                    validate: validateSelectProvider
                }, {
                    label: 'Adatok megadása',
                    component: React.cloneElement(DATA_MAP[data?.payment_service] || <div/>, {reference: dataRef}),
                    validate: validateData
                }]}
                onFinish={() => {
                    setNewOpen(false)
                    const credData = dataRef.current.getData();
                    createPaymentCredential({...credData, ...data})
                }}
            />
        </DinoDialog>

        <DinoDialog
            open={editOpen}
            title='Fizetési mód szerkesztése'
            subtitle='Írd át a fizetési mód nevét és adatait'
            handleClose={() => setEditOpen(false)}
            actions={<Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setEditOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    const data = editRef.current.getData()
                    if (data) {
                        editPaymentCredential(current, data)
                        setEditOpen(false)
                    }
                }} endIcon={<EditRoundedIcon/>}>Szerkesztés</Button>
            </Stack>}
        >
            {React.cloneElement(DATA_MAP[findPaymentCredential(current)?.payment_service] || <div/>, {
                reference: editRef,
                obj: findPaymentCredential(current)
            })}
        </DinoDialog>
    </div>
}

export default Payment