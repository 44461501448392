import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const DinoDialog = ({ children, title, subtitle, open, handleClose, actions }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                style: {
                    borderRadius: '16px',
                    padding: 4,
                },
            }}
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(5px)', // Apply blur effect
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a semi-transparent background
                },
            }}
        >
            <IconButton
                onClick={handleClose}
                color="primary"
                aria-label="close"
                component="label"
                style={{ position: 'absolute', top: 10, right: 10 }}
            >
                <CloseOutlinedIcon />
            </IconButton>
            <DialogTitle>
                <Typography variant="headline5" color="primary.600">
                    {title}
                </Typography>
                <Typography variant="body2" color="#536B74">
                    {subtitle}
                </Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
}

export default DinoDialog