import {Avatar, IconButton, Stack, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SimpleLogo from "../../../assets/SimplePayCircle.png"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DisabledWrapper from "../../../components/DisabledWrapper";
import {hasPermission} from "../../../utils/permissionUtils";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import AuthConfigContext from "../../../context/AuthConfigContext";

const SimpleDisplay = ({credential, onMakeActive, onDeactivate, onEdit, onDelete}) => {
    const {name, active} = credential;
    const {getUser} = useContext(AuthContext)
    const {hasPlan} = useContext(AuthConfigContext);
    const user = getUser()
    const disabledCause = [{
        disabled: !hasPlan('payment', 'create'),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
    }, {
        disabled: !hasPermission(user, 'payment', 'update'),
        message: 'Nincs jogosultságod ehhez a művelethez!'
    }]
    const disabledCauseDelete = [{
        disabled: !hasPlan('payment', 'delete'),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
    }, {
        disabled: !hasPermission(user, 'payment', 'delete'),
        message: 'Nincs jogosultságod ehhez a művelethez!'
    }]
    return <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={2} alignItems='center'>
            <Avatar src={SimpleLogo} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
            <Stack>
                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                    <Typography align="left" variant="subtitle1">{name}</Typography>
                    {active && <Tooltip title="Jelenleg aktív">
                        <div className='active-dot'/>
                    </Tooltip>}
                </Stack>
                <Typography variant='body2'>SimplePay</Typography>
            </Stack>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
            <DisabledWrapper disabledCause={disabledCause}>
                <IconButton variant='dino' onClick={() => onEdit(credential.id)} title="Szerkesztés">
                    <EditIcon/>
                </IconButton>
            </DisabledWrapper>
            <DisabledWrapper disabledCause={disabledCauseDelete}>
                <IconButton variant='dino' onClick={() => onDelete(credential.id)} title="Törlés">
                    <DeleteIcon/>
                </IconButton>
            </DisabledWrapper>


            <DisabledWrapper disabledCause={disabledCause}>
                {!active ?
                    <IconButton variant='dino' onClick={() => onMakeActive(credential.id)} title="Aktiválás">
                        <CheckCircleIcon/>
                    </IconButton>
                    :
                    <IconButton variant='dino' onClick={() => onDeactivate(credential.id)} title="Deaktiválás">
                        <CancelRoundedIcon/>
                    </IconButton>
                }
            </DisabledWrapper>

        </Stack>
    </Stack>
}

export default SimpleDisplay