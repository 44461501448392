import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import greenTheme from "./components/themes/GreenTheme";

import NotFound from "./pages/404";
import Login from "./pages/authentication/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import Dashboard from "./pages/dashboard/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Web from "./pages/website/Web";
import Categories from "./pages/category/Categories";
import Services from "./pages/category/service/Services";
import ServiceDetail from "./pages/category/service/ServiceDetail";
import Colleagues from "./pages/colleague/Colleagues";
import OpeningHours from "./pages/opening-hours/OpeningHours";
import Notifications from "./pages/notification/Notifications";
import Settings from "./pages/settings/Settings";
import Reservations from "./pages/reservation/Reservations";
import ColleagueDetail from "./pages/colleague/ColleagueDetail";
import Register from "./pages/authentication/Registration";
import SuccessfulRegistration from "./pages/authentication/SuccessfulRegistration";
import RegisterExisting from "./pages/authentication/ExistingRegistration";
import CardRegistration from "./pages/authentication/CardRegistration";
import PaymentOver from "./pages/authentication/PaymentOver";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import NewPassword from "./pages/authentication/NewPassword";
import {ErrorProvider} from "./context/ErrorContext";
import ActivationLogin from "./pages/authentication/ActivationLogin";
import ExtraItems from "./pages/extra-items/ExtraItems";
import ExtraItemDetail from "./pages/extra-items/ExtraItemDetail";
import {AuthConfigProvider} from "./context/AuthConfigContext";
import SmsPaymentOver from "./pages/authentication/SmsPaymentOver";
import {LocalizationProvider} from "@mui/x-date-pickers";
import locale from "date-fns/locale/hu";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import Orders from "./pages/order/Orders";
import {ErrorBoundary} from "react-error-boundary";
import GeneralError from "./pages/GeneralError";


function App() {

    return <ThemeProvider theme={greenTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <ConfigProvider>

                <ErrorProvider>
                    <Router>
                        <AuthProvider>
                            <AuthConfigProvider>
                                <ErrorBoundary
                                    FallbackComponent={GeneralError}
                                    onReset={() => {
                                        window.location.reload();
                                    }}>
                                    <Switch>
                                        <PrivateRoute path="/" component={Dashboard} exact/>

                                        <PrivateRoute path="/web" component={Web} exact/>

                                        <PrivateRoute path="/categories" component={Categories} exact/>
                                        <PrivateRoute path="/service/:id" component={Services} exact/>
                                        <PrivateRoute path="/service-detail/:id" component={ServiceDetail} exact/>

                                        <PrivateRoute path="/extra-items" component={ExtraItems} exact/>
                                        <PrivateRoute path="/extra-items/:id" component={ExtraItemDetail} exact/>

                                        <PrivateRoute path="/colleagues" component={Colleagues} exact/>
                                        <PrivateRoute path="/colleague/:id" component={ColleagueDetail} exact/>

                                        <PrivateRoute path="/opening-hours" component={OpeningHours} exact/>

                                        <PrivateRoute path="/notifications" component={Notifications} exact/>

                                        <PrivateRoute path="/settings" component={Settings} exact/>

                                        <PrivateRoute path="/reservations" component={Reservations} exact/>

                                        <PrivateRoute path="/orders" component={Orders} exact/>

                                        <Route path="/login" component={Login} exact/>
                                        <Route path="/activation-login" component={ActivationLogin} exact/>
                                        <Route path="/forgot-password" component={ForgotPassword} exact/>
                                        <Route path="/new-password/:slug" component={NewPassword} exact/>
                                        <Route path="/register" component={Register} exact/>
                                        <Route path="/register-existing/:slug" component={RegisterExisting} exact/>
                                        <Route path="/card-registration" component={CardRegistration} exact/>
                                        <Route path="/payment-over" component={PaymentOver} exact/>
                                        <Route path="/sms-payment-over" component={SmsPaymentOver} exact/>

                                        <Route path="/success" component={SuccessfulRegistration} exact/>
                                        <Route component={NotFound}/>
                                    </Switch>
                                </ErrorBoundary>
                            </AuthConfigProvider>
                        </AuthProvider>
                    </Router>
                </ErrorProvider>

            </ConfigProvider>
        </LocalizationProvider>
    </ThemeProvider>
}

export default App;