import {Avatar, Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import thousandSeparator from "../../utils/numberUtils";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState} from "react";
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import AuthConfigContext from "../../context/AuthConfigContext";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import Loading from "../../components/Loading";
import szamlazz from '../../assets/szamlazz.jpg'
import billingo from '../../assets/billingo_logo.png'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import AuthContext from "../../context/AuthContext";
import DisabledWrapper from "../../components/DisabledWrapper";
import {hasPermission} from "../../utils/permissionUtils";
import DinoDialog from "../../components/Dialog";
import Button from "@mui/material/Button";
import DetailPanel from "../../components/DetailPanel";

const ReservationDetail = ({
                               r,
                               close,
                               destroy,
                               modify,
                               accept,
                               decline,
                               create_invoice,
                               download_invoice,
                               send_invoice
                           }) => {
    const {billingAvailable} = useContext(AuthConfigContext)
    const {getData: fetchReservation} = useCallDataApi('admin-reservation')
    const {getData} = useCallDataApi('service-question')
    const [loading, setLoading] = useState(false)
    const [multipleInvoice, setMultipleInvoice] = useState(false)
    const [questions, setQuestions] = useState([])
    const [documents, setDocuments] = useState([])
    const {getUser} = useContext(AuthContext)
    const {hasPlan} = useContext(AuthConfigContext);
    const user = getUser()

    useEffect(() => {
        setLoading(true)
        Promise.all([
            getData('get_for_business'),
            fetchReservation(`${r?.id}/get_documents`)
        ])
            .then(([q, d]) => {
                setQuestions(q)
                setDocuments(d)
            })
            .finally(() => setLoading(false))
    }, [])

    const prepareQuestion = (i, k, v) => {
        const question = questions?.find(q => q.id === parseInt(k.split('_')[1]))
        if (question?.answer_type === '3') v = v ? 'Igen' : 'Nem'
        return {
            name: question?.question,
            value: v
        }
    }

    const alreadyHasInvoice = () => !!documents?.find(d => d?.document_type === 1)

    const renderDocument = d => {
        const documentTypes = {
            1: 'Számla',
            2: 'Előleg számla',
            3: 'Sztornó számla'
        }
        let serviceTypes = {
            'szamlazz': szamlazz,
            'billingo': billingo
        }
        return <Stack key={d?.id} direction='row' alignItems='center' spacing={2}>
            <Avatar src={serviceTypes[d?.billing_service]}/>
            <Stack>
                <Typography variant='subtitle2'>{d?.document_id}</Typography>
                <Typography variant='body2'>{documentTypes[d?.document_type]}</Typography>
            </Stack>
            <IconButton variant='dino' onClick={() => download_invoice(r?.id, d?.id)} title='Dokumentum letöltése'>
                <DownloadForOfflineRoundedIcon/>
            </IconButton>
            <DisabledWrapper disabledCause={[{
                disabled: !hasPlan('invoice', 'view'),
                message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
            }]}>
                <IconButton variant='dino' onClick={() => send_invoice(r?.id, d?.id)} title='Dokumentum kiküldése'>
                    <SendRoundedIcon/>
                </IconButton>
            </DisabledWrapper>
        </Stack>
    }

    const billingDataProvided = () => {
        return r?.billing_name && r?.billing_country && r?.billing_city && r?.billing_postal_code && r?.billing_address
    }

    const renderName = (status) => {
        const colorMap = {
            pending: '#ED8802',
            accepted: '#478A64',
            rejected: '#6F8E9A',
        }
        const backgroundMap = {
            pending: '#F4B867',
            accepted: '#9ED4B6',
            rejected: '#F1F4F5',
        }
        const statusMap = {
            accepted: 'Elfogadott',
            pending: 'Elfogadásra vár',
            rejected: 'Korábbi vagy elutasított',
        }
        return <Stack direction='row' spacing={2}>
            <Avatar
                sx={{
                    width: 69,
                    height: 69,
                    background: backgroundMap[status],
                    color: colorMap[status]
                }}>
                {r?.first_name?.charAt(0)}{r?.last_name?.charAt(0)}
            </Avatar>
            <Stack spacing={1} alignSelf='center'>
                <Typography variant='headline5'>{r?.name}</Typography>
                <Typography variant='caption' style={{color: colorMap[status]}}>{statusMap[status]}</Typography>
            </Stack>
        </Stack>
    }

    const paymentMap = {
        card: 'Helyszíni fizetés',
        simple: 'Simplepay online',
    }

    const createInvoice = () => {
        if (alreadyHasInvoice()) {
            setMultipleInvoice(true)
        } else {
            create_invoice(r?.id)
        }
    }

    const getBillingAddress = () => {
        return !!r?.billing_address ? `${r?.billing_country?.label} ${r?.billing_postal_code} ${r?.billing_city} ${r?.billing_address}` : '-';
    }

    return <Box padding={{xs: 1, md: 6}}>
        <IconButton
            onClick={close}
            color="primary"
            aria-label="close"
            component="label"
            sx={{position: 'fixed', top: 2, right: 2}}
        >
            <CloseOutlinedIcon/>
        </IconButton>
        <Stack spacing={3}>
            {renderName(r?.display_status)}

            <Typography variant='subtitle1' color='primary.600'>Kliens adatai</Typography>
            <DetailPanel
                details={[{
                    name: 'Kliens neve',
                    value: r?.name
                }, {
                    name: 'Kliens telefonszáma',
                    value: r?.phone
                }, {
                    name: 'Kliens e-mail címe',
                    value: r?.email
                }]}
            />
            <Typography variant='subtitle1' color='primary.600'>Foglalás adatai</Typography>
            <DetailPanel
                details={[{
                    name: 'Kategória',
                    value: r?.price?.service?.category?.name
                }, {
                    name: 'Szolgáltatás',
                    value: r?.price?.service?.name
                }, {
                    name: 'Szolgáltató',
                    value: r?.price?.colleague?.name
                }, {
                    name: 'Ár',
                    value: thousandSeparator(r?.price?.price) + ' Ft'
                }, {
                    name: 'Fizetett előleg',
                    value: thousandSeparator(r?.advance_payed) + ' Ft'
                }, {
                    name: 'Fizetési mód',
                    value: paymentMap[r?.payment]
                }, {
                    name: 'Időpont',
                    value: `${r?.date} | ${r?.start_time} - ${r?.end_time}`
                }, {
                    name: 'Megjegyzés',
                    value: r?.comment || '-'
                }]}
            />
            <Typography variant='subtitle1' color='primary.600'>Számlázási adatok</Typography>
            <DetailPanel
                details={[{
                    name: 'Név',
                    value: r?.billing_name || '-'
                }, {
                    name: 'Adószám',
                    value: r?.billing_vat_number || '-'
                }, {
                    name: 'Cím',
                    value: getBillingAddress()
                }]}
            />
            {r?.extra_items?.length > 0 &&
                <Stack spacing={2}>
                    <Typography variant='subtitle1' color='primary.600'>
                        Termékek
                    </Typography>
                    <DetailPanel details={r?.extra_items?.map(e => ({
                        name: `${e?.name} (${e?.quantity} db)`,
                        value: `${e?.quantity * e?.price} Ft`
                    }))}/>
                </Stack>
            }
            {Object.entries(r?.answers).length > 0 &&
                <Stack spacing={2}>
                    <Typography variant='subtitle1' color='primary.600'>
                        Szolgáltatás specifikus kérdések
                    </Typography>
                    <DetailPanel details={Object.entries(r?.answers)?.map(([k, v], i) => prepareQuestion(i, k, v))}/>
                </Stack>
            }
            {(documents.length > 0 && hasPermission(user, 'invoice', 'view')) &&
                <Stack spacing={2}>
                    <Typography variant='subtitle1' color='primary.600'>
                        Dokumentumok
                    </Typography>
                    {documents?.map(d => renderDocument(d))}
                </Stack>
            }
            <Box sx={{height: '50px'}}/>

            <Stack sx={{position: 'fixed', bottom: 0, background: '#fff', padding: 1, width: '100%'}} spacing={2}>
                <Typography variant='subtitle2' color='primary.main'>Kezelés:</Typography>
                <Stack direction='row' spacing={2} >
                    {r?.display_status === 'accepted' && <DisabledWrapper disabledCause={[{
                        disabled: !hasPermission(user, 'reservation', 'update', r),
                        message: 'Nincs jogosultságod a művelet végrehajtásához!'
                    }]}>
                        <IconButton onClick={() => modify(r.id, '2023-02-26', '11:30', '12:00')} variant='dino'
                                    title='Időpont módosítása'>
                            <CalendarMonthOutlinedIcon/>
                        </IconButton>
                    </DisabledWrapper>}
                    {r?.display_status === 'pending' &&
                        <DisabledWrapper disabledCause={[{
                            disabled: !hasPermission(user, 'reservation', 'accept', r),
                            message: 'Nincs jogosultságod a művelet végrehajtásához!'
                        }]}>
                            <IconButton onClick={() => accept(r.id)} variant='dino' title='Foglalás elfogadása'>
                                <DoneOutlinedIcon/>
                            </IconButton>
                        </DisabledWrapper>}
                    {r?.display_status === 'pending' &&
                        <DisabledWrapper disabledCause={[{
                            disabled: !hasPermission(user, 'reservation', 'decline', r),
                            message: 'Nincs jogosultságod a művelet végrehajtásához!'
                        }]}>
                            <IconButton onClick={() => decline(r.id)} variant='dino' title='Foglalás elutasítása'>
                                <CloseOutlinedIcon/>
                            </IconButton>
                        </DisabledWrapper>}
                    {(billingAvailable() && billingDataProvided()) &&
                        <DisabledWrapper disabledCause={[{
                            disabled: !hasPlan('invoice', 'create'),
                            message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
                        }, {
                            disabled: !hasPermission(user, 'invoice', 'create'),
                            message: 'Nincs jogosultságod a művelet végrehajtásához!'
                        }]}>
                            <IconButton onClick={createInvoice} variant='dino' title='Számla kiállítása'>
                                <ReceiptRoundedIcon/>
                            </IconButton>
                        </DisabledWrapper>}
                    {['accepted', 'rejected'].includes(r?.display_status) &&
                        <DisabledWrapper disabledCause={[{
                            disabled: !hasPermission(user, 'reservation', 'delete', r),
                            message: 'Nincs jogosultságod a művelet végrehajtásához!'
                        }]}>
                            <IconButton onClick={destroy} variant='dino' title='Foglalás törlése'>
                                <DeleteOutlineOutlinedIcon/>
                            </IconButton>
                        </DisabledWrapper>}
                </Stack>
            </Stack>
        </Stack>
        <Loading isLoading={loading}/>

        <DinoDialog
            open={multipleInvoice}
            title='Figyelem!'
            subtitle='Ehhez a megrendeléshez már tartozik számla, biztosan folytatod?'
            actions={<>
                <Button variant='outlined' onClick={() => setMultipleInvoice(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setMultipleInvoice(false)
                    create_invoice(r?.id)
                }} endIcon={<ReceiptRoundedIcon/>}>Igen</Button>
            </>}
            handleClose={() => setMultipleInvoice(false)}
        />
    </Box>
}

export default ReservationDetail