import sadDino from '../assets/dinos/035-crying.png'
import Typography from "@mui/material/Typography";
import AuthBase from "./AuthBase";
import Button from "@mui/material/Button";

const GeneralError = () => {

    return <AuthBase label='Hiba'>
        <div className="not-found-container">
            <Typography variant="headline2">Hiba történt!</Typography>
            <img src={sadDino} alt="sad dino" style={{width: '20%'}}/>
            <Button onClick={() => window.location.reload()} variant='contained'>Frissítés</Button>
        </div>
    </AuthBase>

}

export default GeneralError