import {Stack, Tooltip} from "@mui/material";
import {useContext, useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomSwitch from "../../../components/formValidation/CustomSwitch";
import DisabledWrapper from "../../../components/DisabledWrapper";
import AuthConfigContext from "../../../context/AuthConfigContext";

const Payment = ({reference, data, setData, paymentAuthorized}) => {
    const {hasPlan} = useContext(AuthConfigContext);
    const [errors, setErrors] = useState({
        webshop_cash_payment: false,
        webshop_card_payment: false,
    });

    const validators = {
        webshop_cash_payment: [],
        webshop_card_payment: [],
    }

    const validateAtLeastOneSelected = obj => {
        const { webshop_cash_payment, webshop_card_payment, is_pay_advance } = obj
        const isAtLeastOneSelected = webshop_cash_payment || webshop_card_payment || is_pay_advance
        return {valid: isAtLeastOneSelected, error: 'Legalább egy fizetési lehetőséget ki kell választani!'}
    }
    const disabledCause = [
        {
            disabled: !hasPlan('payment', 'update'),
            message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a beállításokban a használathoz!'
        },
        {
            disabled: !paymentAuthorized,
            message: 'Nincs bekapcsolva az online fizetés! A beállítások menüpontban tudod hozzáadni a fizetési módot.'
        },
    ];
    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference} customValidators={[validateAtLeastOneSelected]}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='webshop_cash_payment'
                labelOn='Helyszíni fizetés bekapcsolva'
                labelOff='Helyszíni fizetés kikapcsolva'
            />
            <DisabledWrapper disabledCause={disabledCause}>
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='webshop_card_payment'
                    labelOn='Online fizetés bekapcsolva'
                    labelOff='Online fizetés kikapcsolva'
                />
            </DisabledWrapper>
        </Stack>
    </ErrorHandlingForm>
}

export default Payment