import React from 'react';
import { Stack, Typography, Box } from '@mui/material';

const DetailPanel = ({ details }) => {
    return (
        <Stack>
            {details.map((item, index) => (
                <Stack
                    key={index}
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{backgroundColor: index % 2 === 0 ? 'primary.50' : 'transparent', padding: 1}}
                    justifyContent="space-between"
                >
                    <Typography variant="subtitle2">{item.name}</Typography>
                    <Typography variant="body1" color="primary.600">
                        {item.value}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
};

export default DetailPanel;
