import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import {useContext, useState} from "react";
import ConfigContext from "../../../context/ConfigContext";
import {useSnackbar} from "notistack";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";

const Calendar = () => {
    const [loading, setLoading] = useState(false)
    const {baseUrl} = useContext(ConfigContext)
    const {getData: fetchBusiness} = useCallDataApi('business')
    const {enqueueSnackbar} = useSnackbar()

    const copyCalendarLink = () => {
        setLoading(true)
        fetchBusiness('get_calendar_slug/')
            .then(r => {
                navigator.clipboard.writeText(baseUrl + '/business-cal/' + r?.slug)
                    .then(() => enqueueSnackbar('Kimásoltad a linket!', {variant: 'success'}))
                    .catch(() => enqueueSnackbar('Nem sikerült kimásolni a linket!', {variant: 'error'}))
            }).finally(() => setLoading(false))
    }

    return <div className="white-card">
        <Typography variant='subtitle2'>Naptár megosztása</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>
            Ezzel a linkkel tudod beállítani a
            Google és egyéb naptáraidat.
        </Typography>
        <Button
            variant='contained'
            sx={{marginTop: 2}}
            onClick={() => copyCalendarLink()}
            endIcon={<ContentCopyRoundedIcon/>}>
            Link másolása
        </Button>

        <Loading isLoading={loading}/>
    </div>
}

export default Calendar